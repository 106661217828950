/* 
    z-index's :
    background color is -1 
    background images is 0
    content is 1    
*/

/* *:not(body, html, .background) {
    z-index: 1;
} */

body, html {
    margin: 0;
    padding: 0;
    background-color: rgb(80,80,80);
    z-index: -1;
    background-image: url(./images/background.svg);
    background-repeat: no-repeat;
}

@keyframes up-down {
    0% {
        bottom: 40px;
        opacity: 0%;    
    }
    100% {
        bottom: 0px;
        opacity: 100%;
    }
}

@keyframes grow {
    0% {
        opacity: 0%;
        height: 0;
        width: 0px;
        font-size: 0%;
    }
    100% {
        opacity: 100%;
        font-size: max(16pt, 2vw);
        width: max(115px,15vw);
        height: max(30px,8vh);
    }
}

@keyframes map-grow {
    0% {
        font-size: 0%;
        opacity: 0%;
        height: 0%;
        width: 0px;
    }
    100% {
        opacity: 100%;
        height: 60vh;
        width: 50vw;
    }
}

.background {
    /* background by SVGBackgrounds.com */
    background-image: url("./images/background.svg");
    background-repeat: repeat;
    background-position: left;
    background-size: cover;
    overflow: hidden;
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
} 

.title1 {
    position: relative;
    text-align: center;
    color: aliceblue;
    margin-bottom: 0;
    margin-top: 20px;
    padding: 0;
    font-family: "PT Sans", Calibri, Tahoma, sans-serif;
    font-size: min(52pt,12vw);
    animation-name: up-down;
    animation-duration: 2s;
}

.title2 {
    position: relative;
    text-align: center;
    text-wrap: wrap;
    color: aliceblue;
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0;
    font-family: "PT Sans", Calibri, Tahoma, sans-serif;
    font-size: min(32pt,8vw);
    animation-name: up-down;
    animation-duration: 2s;
}

.map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: relative;
}

.map {
    height: 60vh;
    width: max(60vw, 350px);
    margin-top: 0;
    margin-bottom: 5vh;
    animation-name: up-down;
    animation-duration: 1s;
    z-index: 1;
}

.image {
    height: 60vh;
    width: min(85vw,700px);
    animation-name: up-down;
    animation-duration: 1s;
}

.result {
    position: relative;
    text-align: center;
    color: aliceblue;
    font-family: "PT Sans", Calibri, Tahoma, sans-serif;
    font-size: 52px;
    animation-name: up-down;
    animation-duration: 1s;
}

.ready-btn {
    font-family: "PT Sans", Calibri, Tahoma, sans-serif;
    font-size: max(16pt, 2vw);
    font-weight: 600;
    color: rgb(80,80,80);
    margin: 20px;
    width: max(115px,15vw);
    height: max(30px,8vh);
    border-radius: 25px;
    background: aliceblue;
    position: relative;
    animation: grow;
    animation-duration: 1s;
}